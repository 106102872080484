import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from '@reach/router'
import { Button } from '@ufx-ui/core'
import cx from 'classnames'
import _isEmpty from 'lodash/isEmpty'
import React, { useState, useContext } from 'react'
import { Row, Col } from 'react-flexbox-grid'

import { MIN_SATS, apiURL } from '../../config'
import AppContext from '../../context/AppContext'
import useWindowSize from '../../hooks/useWindowSize'
import { getEpisodeNum, getEpisodePercent } from '../../utils'
import AudioPlayer from '../AudioPlayer'
import CharityText from '../CharityText'
import Contribute from '../Contribute'
import EpisodeImage from '../EpisodeImage'
import CardSkeleton from '../Skeleton/CardSkeleton'
import GoalProgress from './GoalProgress'
import { paramCase } from 'change-case'

function EpisodeCard({ episode = {}, loading }) {
  const {
    id,
    subtitle = '',
    episodeNumber,
    title,
    description,
    avatar,
    charity,
    goal,
    funded,
    src,
  } = episode
  const [episodeNum, episodeNumStr] = getEpisodeNum(episodeNumber)
  const percent = getEpisodePercent(episode)

  const [isShake, setIsShake] = useState(false)
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [duration, setDuration] = useState(0)
  const { isMD: isMobile } = useWindowSize()

  const { bitcoinAddress, lightningAddress } = useContext(AppContext)
  const showQRCode = lightningAddress || bitcoinAddress

  const shakeContributeBtn = shake => {
    if (shake) {
      setIsShake(true)
    }
  }

  if (loading || _isEmpty(episode)) {
    return <CardSkeleton />
  }

  const stopPropagation = e => e.stopPropagation()

  const contributeBtn = (
    <Button
      className={cx('contribute', { shake: isShake })}
      onAnimationEnd={() => setIsShake(false)}
      intent='primary'
      onClick={e => {
        e.stopPropagation()
        setShowPaymentModal(true)
      }}
    >
      Contribute
    </Button>
  )

  const goalProgress = (
    <GoalProgress goal={goal} percent={percent} isMobile={isMobile} />
  )

  const episodeImage = (
    <EpisodeImage avatar={avatar} episodeNum={episodeNumStr} title={title} />
  )

  const episodeInfo = (
    <>
      <div className='heading-3'>{subtitle}</div>
      <p className='desc'>{description}</p>
      <CharityText charity={charity} />
    </>
  )

  const contributeSection = (
    <Contribute
      id={id}
      avatar={avatar}
      title={title}
      goal={goal}
      duration={duration}
      charity={charity}
      episodeNum={episodeNumStr}
      onClose={() => setShowPaymentModal(false)}
    />
  )

  if (isMobile) {
    return (
      <div className='episode-card'>
        <Row>
          <Col md={12}>{episodeImage}</Col>

          <Col
            md={12}
            className={cx('content', 'show-hide-effect', {
              hide: showPaymentModal,
            })}
            onClick={stopPropagation}
          >
            <div>
              <div className='mobile-goal-row'>{goalProgress}</div>
              {episodeInfo}
            </div>
          </Col>

          <Col
            className={cx('content', 'show-hide-effect', {
              hide: !showPaymentModal,
            })}
            md={showQRCode ? 12 : 7}
          >
            <div className='mobile-goal-row'>{goalProgress}</div>
            {contributeSection}
          </Col>
        </Row>

        <Row
          className={cx('player-row', 'show-hide-effect', {
            hide: showPaymentModal,
          })}
          onClick={stopPropagation}
        >
          <Button
            intent='secondary'
            className='play-btn'
            onClick={() =>
              navigate(`/episodes/${episodeNumber}/${paramCase(subtitle)}`)
            }
          >
            <FontAwesomeIcon icon={faPlay} />
            <span>Listen</span>
          </Button>

          {/* add hidden player to get trigger setDurationCallback cb */}
          <AudioPlayer
            paidPercentage={percent}
            src={apiURL(src)}
            disabled={funded < MIN_SATS}
            onEnded={shakeContributeBtn}
            downloadable={percent >= 100}
            setDurationCallback={setDuration}
            showOnlyHiddenPlayer
          />
          <div>{contributeBtn}</div>
        </Row>
      </div>
    )
  }

  // desktop layout
  return (
    <div className='episode-card'>
      <div
        className={cx('goal', 'show-hide-effect', { hide: showPaymentModal })}
      >
        <div className='progress-btn'>{goalProgress}</div>
        <div className='contribute-wrapper'>{contributeBtn}</div>
      </div>

      <Row>
        <Col md={5} className={cx('show-hide-effect', { hide: showQRCode })}>
          {episodeImage}
        </Col>

        {!showPaymentModal ? (
          <Col md={7} className={cx('content')} onClick={stopPropagation}>
            <div>{episodeInfo}</div>
          </Col>
        ) : (
          <Col className='content' md={showQRCode ? 12 : 7}>
            {contributeSection}
          </Col>
        )}
      </Row>

      <Row
        className={cx('player-row', 'show-hide-effect', {
          hide: showPaymentModal,
        })}
        onClick={stopPropagation}
      >
        <AudioPlayer
          paidPercentage={percent}
          src={apiURL(src)}
          disabled={funded < MIN_SATS}
          onEnded={shakeContributeBtn}
          downloadable={percent >= 100}
          setDurationCallback={setDuration}
          showControls
        />
      </Row>
    </div>
  )
}

export default EpisodeCard
